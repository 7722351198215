<template>
  <label @click="input.click()">
    <template name="button">
      <i class="iconfont iconadd-circle" v-if="addIsShow"></i>
      <div v-else style="white-space: nowrap">
        <i class="el-icon-loading"></i>
        上传中...
      </div>
    </template>
  </label>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    type: {
      type: String,
      default: 'file',
    },
    fields: {
      type: Object,
      default: function () {
        return {
          name: 'attachmentName',
          path: 'attachmentPath',
          type: 'attachmentType',
        }
      },
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      addIsShow: true,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {},
  mounted() {
    try {
      this.input = document.createElement('input')
      this.input.setAttribute('type', 'file')
      if (this.multiple) {
        this.input.setAttribute('multiple', 'multiple')
      }
      this.input.addEventListener('change', async e => {
        this.addIsShow = false
        var files = e.target.files
        var arr = []
        // for (let i = 0; i < files.length; i++) {
        //   var form = new FormData()
        //   form.append('file', files[i])
        //   form.append('type', this.type)
        //   this.$api.common
        //     .uploadFile(form)
        //     .then(res => {
        //       //setTimeout(()=>{
        // arr[i].loading = false
        // arr[i][`${this.fields.path}`] = res.data.filePath
        // arr[i][`${this.fields.name}`] = res.data.fileName
        // arr[i][`${this.fields.type}`] = this.type
        //       //},10000)
        //       this.input.value = ''
        //     })
        //     .catch(err => {
        //       this.input.value = ''
        //     })
        // let obj = {
        //   loading: true,
        // }
        // obj[`${this.fields.name}`] = files[i].name
        // obj[`${this.fields.path}`] = ''
        // obj[`${this.fields.type}`] = this.type
        // arr.push(obj)
        // }
        // this.$emit('upload', arr)
        for (let i = 0; i < files.length; i++) {
          var form = new FormData()
          form.append('file', files[i])
          form.append('type', this.type)
          const res = await this.$api.common.uploadFile(form)
          arr.push({
            loading: false,
            [`${this.fields.path}`]: res.data.filePath,
            [`${this.fields.name}`]: res.data.fileName,
            [`${this.fields.type}`]: this.type,
            fileType: res.data.fileType, // 正常附件 NORMAL
          })
          if (i === files.length - 1) {
            this.$emit('upload', arr)
            this.input.value = ''
          }
        }
        this.$message.success('上传成功')
        this.addIsShow = true
      })
    } catch (error) {
      this.$message.error('上传失败，请重新上传')
      this.addIsShow = true
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped></style>
